<template>
  <div>
    <b-modal ref="my-modal" centered cancel-variant="outline-secondary" title-tag="div">
      <validation-observer ref="documentsimple">
        <b-card-code>
          <b-form v-for="(document, index) in documents" :key="index">
            <validation-provider #default="{ errors }" rules="required">
              <b-form-group
                label-for="cardNumber"
                :state="errors.length > 0 ? false : null"
              >
                <label> نوع الوثيقة</label>
                <v-select
                  v-model="document.document_type"
                  :options="optionDocuments"
                  label="name"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <validation-provider #default="{ errors }" rules="regex:^([0-9]+)$|required">
              <b-form-group
                label-for="numberdocument"
                :state="errors.length > 0 ? false : null"
              >
                <label> رقم الوثيقة</label>

                <b-form-input v-model="document.number" placeholder="رقم الوثيقة" />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <validation-provider #default="{ errors }" name="daigsp" rules="required">
              <b-form-group label-for="dathhhe" :state="errors.length > 0 ? false : null">
                <label> تاريخ الأصدار</label>

                <flat-pickr
                  v-model="document.release_date"
                  class="form-control"
                  placeholder="حدد تاريخ الأصدار"
                  :config="{ static: 'true' }"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="dateDisp"
              rules=""
              v-if="document.document_type == 4"
            >
              <b-form-group label-for="date" :state="errors.length > 0 ? false : null">
                <label> تاريخ الأنتهاء</label>
                <flat-pickr
                  v-model="document.expiration_date"
                  class="form-control"
                  :config="{ static: 'true' }"
                  placeholder="حدد تاريخ الأنتهاء"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <b-form-group label-for="docNote">
              <label>الملاحظات</label>
              <b-form-textarea
                id="textarea-default"
                v-model="document.notes"
                placeholder="الملاحظات"
                rows="1"
              />
            </b-form-group>
            <hr />
          </b-form>
        </b-card-code>
        <b-row>
          <b-col xl="4"></b-col>
          <b-col xl="4">
            <b-button variant="outline-primary" @click="adddocument">
              <span class="align-middle"> إضافة الوثيقة </span>
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <b-table responsive="sm" :items="itemsDoc" :fields="fields" />
      </validation-observer>

      <template #modal-footer>
        <!-- <b-row>
<b-col xl="4"></b-col>
<b-col xl="4"> -->

        <b-button variant="purple" @click="addDocumenttoFamily">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle"> حفظ </span>
        </b-button>
        <!-- </b-col>

                </b-row> -->
      </template>
    </b-modal>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from "vue-select";
// import person from './person.vue';

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormFile,
  BFormGroup,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BModal,
  BTable,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";

// import "flatpickr/dist/flatpickr.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, max, is_earlier } from "@validations";

import { BFormDatepicker, BFormInput, BFormTextarea, BButton } from "bootstrap-vue";
import { personDetails } from "@/@core/mixins/personDetails.js";
// import {
//   BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
// } from 'bootstrap-vue'
export default {
  name: "family-number",
  mixins: [personDetails],

  components: {
    BModal,
    is_earlier,
    BCardCode,
    required,
    BFormInvalidFeedback,
    BButton,
    BTable,
    max,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    flatPickr,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    ToastificationContent,
    BRow,

    BCol,
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    return {
      maxDate,
      fields: [
        { key: "name", label: "الوثيقة" },
        { key: "number", label: "الرقم" },
        { key: "notes", label: "الملاحظة" },
      ],

      items: [],
      optionDocuments: [],
      alldocuments: [],
    };
  },
  created() {
    this.getAlldrowpDown();
  },
  methods: {
    showdocument() {
      this.$refs["my-modal"].show();
    },

    resetModal() {
      this.documents = [
        {
          release_date: "",
          expiration_date: "",
          number: null,
          document_type_id: null,
          notes: "",
        },
      ];
      requestAnimationFrame(() => {
        this.$refs.documentsimple.reset();
      });
    },
    getAlldrowpDown() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let family_document_types = res.data.data.family_document_types;
        //console.log(family_document_types);

        family_document_types.forEach((el) => {
          this.optionDocuments.push({ name: el.name, id: el.id});
        });
      });
    },
    addDocumenttoFamily() {
      let FamilyDocuments = [];
      this.alldocuments.forEach((el) => {
        FamilyDocuments.push({
          document_type: el.document_type.id,
          release_date: el.release_date,
          expiration_date: el.expiration_date,
          notes: el.notes,
          number: el.number,
        });
      });
      this.$emit("getFamilyDocuments", FamilyDocuments);
      this.$refs["my-modal"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.btn-purple {
  color: white;
  background-color: #00468e !important;
  width: 200px;
}
.btn-edit {
  color: white;
  width: 200px;
  background-color: #28c76f !important;
}
.btn-delete {
  color: white;
  width: 200px;
  background-color: #ea5455 !important;
}
.label {
  font-size: 15px;
}
.flatpickr-wrapper {
  position: relative;
  display: block !important;
}
.but {
  top: 25px;
}
</style>
