<template>
  <div>
    <b-card-code>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-col md="6" xl="4">
              <validation-provider
                #default="validationContext"
                name="family"
                rules="required"
              >
                <b-form-group
                  label-for="familyNatural"
                  :state="getValidationState(validationContext)"
                >
                  <label> طبيعة العائلة</label>

                  <v-select
                    v-model="familyFile.family_status_id"
                  
                    :options="FamilyStatus"
                    label="name"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="4">
              <b-form-group label-for="phone">
                <label>تاريخ التسجيل</label>
                  <flat-pickr
                    v-model="familyFile.registration_date"
                    class="form-control"
                    placeholder="حدد تاريخ التسجيل"
                    :config="{ maxDate: maxDate }"
                  />
                
              </b-form-group>
            </b-col>

            <b-col md="6" xl="4" v-if="familyFile.family_status_id !== 2">
              <validation-provider
                #default="validationContext"
                name="dateDisp"
                :rules="rules"
              >
                <b-form-group
                  label-for="date"
                  :state="getValidationState(validationContext)"
                >
                  <label>تاريخ النزوح</label>
                  <flat-pickr
                    v-model="familyFile.displacement_date"
                    class="form-control"
                    placeholder="حدد تاريخ النزوح"
                    :config="{ maxDate: maxDate }"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="4">
              <validation-provider
                #default="validationContext"
                name="titlekn"
                rules="required"
              >
                <b-form-group
                  label-for="know"
                  :state="getValidationState(validationContext)"
                >
                  <label>طريقة التعرف على المركز</label>

                  <v-select
                    v-model="familyFile.recognized_by_method_id"

                    :options="RecognitionBy"
                    label="name"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <!-- notes -->
            <b-col md="12" xl="4">
              <b-form-group label-for="notes">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="familyFile.notes"
                  placeholder="الملاحظات"
                  rows="2"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />

          <h3>مكان الإقامة الحالي</h3>
          <br />

          <b-row>
            <b-col md="6" xl="3">
              <validation-provider
                #default="validationContext"
                name="city"
                rules="required"
              >
                <b-form-group
                  label-for="city"
                  :state="getValidationState(validationContext)"
                >
                  <label> المحافظة</label>
                  <b-form-input v-model="selectedCity.label" readonly />
                </b-form-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider
                #default="validationContext"
                name="area"
                rules="required"
              >
                <b-form-group
                  label-for="area"
                  :state="getValidationState(validationContext)"
                >
                  <label> المنطقة</label>
                  <v-select
                    v-model="selectedArea"
                    :reduce="(val) => val.value"
                    :options="optionCity"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider
                #default="validationContext"
                name="village"
                rules="required"
              >
                <b-form-group
                  label-for="village"
                  :state="getValidationState(validationContext)"
                >
                  <label> الناحية</label>
                  <v-select
                    v-model="familyFile.current_address.town_id"
                    :reduce="(val) => val.value"
                    :options="optionVillages"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider #default="validationContext" name="town" rules="">
                <b-form-group
                  label-for="village"
                  :state="getValidationState(validationContext)"
                >
                  <label> الحي</label>
                  <v-select
                    v-model="familyFile.current_address.neighborhood_id"
                    :reduce="(val) => val.value"
                    :options="optionTown"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="3">
              <validation-provider
                #default="validationContext"
                name="placetype"
                rules="required"
              >
                <b-form-group
                  label-for="place"
                  :state="getValidationState(validationContext)"
                >
                  <label> نوع السكن</label>
                  <v-select
                    v-model="familyFile.current_address.residence_type_id"
                    :reduce="(val) => val.id"
                    :options="ResidenceTypes"
                    label="name"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3" v-if="familyFile.current_address.residence_type_id == 6">
              <b-form-group label-for="number">
                <validation-provider
                  #default="validationContext"
                  name="Address"
                  rules="required"
                >
                  <label> تفاصيل العنوان</label>

                  <b-form-input
                    v-model="familyFile.current_address.description"
                    :state="getValidationState(validationContext)"
                    placeholder=""
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <hr />
          <diV v-if="familyFile.family_status_id !== 2">
            <h3>مكان الإقامة الأصلي</h3>
            <br />

            <b-row>
              <br />
              <br />
              <b-col md="6" xl="3">
                <validation-provider
                  #default="validationContext"
                  name="oldcountry"
                  rules="required"
                >
                  <b-form-group
                    label-for="country"
                    :state="getValidationState(validationContext)"
                  >
                    <label> البلد</label>
                    <v-select
                      v-model="selectedOCounrtry"
                      :reduce="(val) => val.value"
                      :options="optionCountry"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="3">
                <validation-provider
                  #default="validationContext"
                  name="oldcity"
                  rules="required"
                >
                  <b-form-group
                    label-for="city"
                    :state="getValidationState(validationContext)"
                  >
                    <label> المحافظة</label>
                    <v-select
                      v-model="selectedOldCity"
                      :reduce="(val) => val.value"
                      :options="optionOgovernorate"
                      @input="SetAreaCity()"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="3">
                <validation-provider
                  #default="validationContext"
                  name="oldarea"
                  rules="required"
                >
                  <b-form-group
                    label-for="area"
                    :state="getValidationState(validationContext)"
                  >
                    <label> المنطقة</label>
                    <v-select
                      v-model="selectedOldArea"
                      :reduce="(val) => val.value"
                      :options="optionOCity"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="3">
                <validation-provider
                  #default="validationContext"
                  name="oldvillage"
                  rules="required"
                >
                  <b-form-group
                    label-for="village"
                    :state="getValidationState(validationContext)"
                  >
                    <label> الناحية</label>
                    <v-select
                      v-model="familyFile.original_address.town_id"
                      :reduce="(val) => val.value"
                      :options="optionOVillages"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="3">
                <validation-provider #default="validationContext" name="oldtown" rules="">
                  <b-form-group
                    label-for="village"
                    :state="getValidationState(validationContext)"
                  >
                    <label> الحي</label>
                    <v-select
                      v-model="familyFile.original_address.neighborhood_id"
                      :reduce="(val) => val.value"
                      :options="optionOTown"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="3">
                <validation-provider
                  #default="validationContext"
                  name="notesPlace"
                  rules="required"
                >
                  <b-form-group
                    label-for="place"
                    :state="getValidationState(validationContext)"
                  >
                    <label> نوع السكن</label>
                    <v-select
                      v-model="familyFile.original_address.residence_type_id"
                      :reduce="(val) => val.id"
                      :options="ResidenceTypes"
                      label="name"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="3"
                v-if="familyFile.original_address.residence_type_id == 6"
              >
                <b-form-group label-for="number">
                  <validation-provider
                    #default="validationContext"
                    name="oldAddress"
                    rules="required"
                  >
                    <label> تفاصيل العنوان</label>

                    <b-form-input
                      v-model="familyFile.original_address.description"
                      :state="getValidationState(validationContext)"
                      placeholder=""
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </diV>

          <b-row>
            <br />
            <br />

            <b-col md="6" xl="4">
              <b-button variant="purple" @click="addNumber">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">إضافة وسيلة اتصال </span>
              </b-button>
              <feather-icon class="text-success" icon="CheckIcon" v-if="saveNumbers" />
            </b-col>

            <b-col md="6" xl="4">
              <b-button variant="purple" @click="addDocumentFamily">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">إضافة وثيقة </span>
              </b-button>
              <feather-icon class="text-success" icon="CheckIcon" v-if="saveDocuments" />
            </b-col>
          </b-row>
          <br />
          <br />
          <br />
          <b-row>
        
            <b-col md="12" xl="4"></b-col>
            
            <b-col class="border-Primary" md="12" xl="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                type="submit"
                :disabled="isAddFamilydone"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> إنشاء ملف العائلة</span>
              </b-button>
            </b-col>

            <b-col md="12" xl="4"></b-col>
            
            
          </b-row>
        </b-form>
      </validation-observer>

      <family-number @getFamilyPhone="getFamilyPhone" ref="childComponent" />
      <family-document @getFamilyDocuments="getFamilyDocuments" ref="documentComponent" />
    </b-card-code>
  </div>
</template>

<script>
import vSelect from "vue-select";

import familyNumber from "./add-family-number.vue";
import familyDocument from "./add-family-documents.vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BButtonGroup,
  BCardTitle,
  BCol,
  BFormFile,
  BFormGroup,
  BFormTextarea,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BTab,
  BTabs,
  BProgress,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import store from "@/store";
import Vue from "vue";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import Ripple from "vue-ripple-directive";
import formValidation from "@core/comp-functions/forms/form-validation";
import { BFormDatepicker, BFormInput, BButton } from "bootstrap-vue";
import { location } from "@/@core/mixins/location.js";
import addfamilyStoreModule from "./addfamilyStoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  setup(context) {
    const ADD_FAMILY_APP_STORE_MODULE_NAME = "app-add-family";
    const toast = useToast();
    // Register module
    if (!store.hasModule(ADD_FAMILY_APP_STORE_MODULE_NAME))
      store.registerModule(ADD_FAMILY_APP_STORE_MODULE_NAME, addfamilyStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ADD_FAMILY_APP_STORE_MODULE_NAME))
        store.unregisterModule(ADD_FAMILY_APP_STORE_MODULE_NAME);
    });
    //
    const { refFormObserver, getValidationState } = formValidation(() => {});
    const RecognitionBy = ref([]);
    const FamilyStatus = ref([]);
    const ResidenceTypes = ref([]);
    const famId = ref('')
    const blankfamilyFile = {
      family_status_id: "",
      displacement_date: "",
      registration_date: "",
      notes: "",
      recognized_by_method_id: "",
      family_contact_details: [],
      family_documents: [],
      original_address: {
        neighborhood_id: "",
        town_id: "",
        residence_type_id: "",
        description: "",
      },
      current_address: {
        neighborhood_id: "",
        town_id: "",
        residence_type_id: "",
        description: "",
      },
    };
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    const familyFile = ref(JSON.parse(JSON.stringify(blankfamilyFile)));

    watch(
      () => familyFile.current_address,
      (currentValue, oldValue) => {}
    );
    const getRecognitionBy = () => {
      store.dispatch("app-add-family/getRecognitionBy").then((response) => {
        RecognitionBy.value = response.data.data;
      });
    };
    const getResidenceTypes = () => {
      store.dispatch("app-add-family/getResidenceTypes").then((response) => {
        ResidenceTypes.value = response.data.data;
      });
    };
    const getFamilyStatus = () => {
      store.dispatch("app-add-family/getFamilyStatus").then((response) => {
        FamilyStatus.value = response.data.data;
      });
    };
    function replaceUndefinedOrNull(key, value) {
      if (value === null || value === "" || value === undefined || value === "{}") {
        return undefined;
      }

      return value;
    }
    function isEmptyObject(obj) {
      return JSON.stringify(obj) === "{}";
    }
    let isAddFamilydone=false
    getRecognitionBy();




    getFamilyStatus();
    getResidenceTypes();
    const onSubmit = () => {
      let datajson = JSON.stringify(familyFile.value, replaceUndefinedOrNull);
      let dataparse = JSON.parse(datajson);

      // for (let key of Object.keys(dataparse)) {
      //   if (isEmptyObject(dataparse[key])) {
      //     delete dataparse[key];
      //   }
      // }
      Object.keys(dataparse).forEach(key => {
    if (dataparse[key] === '') {
      delete dataparse[key];
    }
  });

      store.dispatch("app-add-family/addFamily", dataparse).then((response) => {
        let familyId = response.data.data.id;
      //  isAddFamilydone = false;

        store.commit("getId", familyId);
        

        //save family id for update
        // famId.value= familyId;
        console.log("id for family "+ familyId);

        Vue.swal({
          title: "",
          text: "تمت أضافة العائلة بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    };
    return {
      familyFile,
      isAddFamilydone,
      onSubmit,
     
      refFormObserver,
      getValidationState,
      enterperson: false,
      isAddFamily: true,
      maxDate,
      saveNumbers: false,
      saveDocuments: false,

      placeODetails: "",
      placeDetails: "",
      RecognitionBy,
      ResidenceTypes,
      FamilyStatus,
      // selectedTown,
      // selectedVillage,
      // FamilyNumber: "",
      // selectedOldHome: "",

      // selectedHomeType: "",

      // dateReg: "",
      // dateDisp: "",
      rules: [],
      phones: [],
      Documents: [],
      // notesFamily: "",
      // selectknow: "",

      // selectFamily,
    };
  },

  name: "add-family",
  mixins: [location],

  components: {
    BCardCode,
    ToastificationContent,
    BButtonGroup,
    BTab,

    familyNumber,
    familyDocument,

    max,
    required,
    BFormInvalidFeedback,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BCardTitle,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,
    flatPickr,
    BCol,
  },

  data() {
    return {
      optionTown: [],
      optionOTown: [],
    };
  },

  watch: {
    "selectedArea": function (currentValue) {
      this.familyFile.current_address.town_id=''
      this.familyFile.current_address.neighborhood_id=''
    },
    "selectedOldArea": function (currentValue) {
      this.familyFile.original_address.town_id=''
      this.familyFile.original_address.neighborhood_id=''
    },
    "familyFile.current_address.town_id": function (currentValue) {
      this.optionTown = [];

      let url = `/api/v1/towns/${currentValue}/neighborhoods`;
      this.$http.get(url).then((response) => {
        let towns = response.data.data;

        towns.forEach((el) => {
          this.optionTown.push({ label: el.name, value: el.id });
        });
      });
    },
    "familyFile.original_address.town_id": function (currentValue) {
      this.optionOTown = [];

      let url = `/api/v1/towns/${currentValue}/neighborhoods`;
      this.$http.get(url).then((response) => {
        let towns = response.data.data;

        towns.forEach((el) => {
          this.optionOTown.push({ label: el.name, value: el.id });
        });
      });
    },
  },

  directives: {
    Ripple,
  },

  created() {
    this.getCountry();
    this.callFunction(),
      (this.rules = {
        required,
        is_earlier: {
          compare: this.dateReg,
          dateType: "تاريخ التسجيل",
        },
      });
  },

  mounted() {
    this.$swal({
      title: "",
      text:
        "تم اخذ موافقة الشخص المعني على جمع بياناته الشخصية وتم اعلامه بان الهدف من جمع هذه البيانات هو تقديم الخدمة المناسبة له وبانه سيتم التعامل مع بياناته الشخصية بسريه تامة",
      // eslint-disable-next-line global-require
      imageUrl: require("@/assets/images/logo/logo.jpg"),

      imageWidth: 400,
      allowEscapeKey: false,
      imageAlt: "Custom image",
      allowOutsideClick: false,
      confirmButtonText: "موافق",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
      focusConfirm: true,
    });
  },

  methods: {
    addNumber() {
      this.$refs.childComponent.show();
    },

    addDocumentFamily() {
      this.$refs.documentComponent.showdocument();
    },
    getCountry() {
      this.$http.get("/api/v1/countries").then((res) => {
        let country = res.data.data;
        country.forEach((el) => {
          this.optionCountry.push({ value: el.id, label: el.name });
        });
      });
    },
    SetAreaCity() {
     
     this.selectedOldArea = ''
     this.familyFile.original_address.town_id = ''
     this.familyFile.original_address.neighborhood_id = ''
   },

    addFamilydone() {
      this.isAddFamily = false;

      this.$refs.childComponent.resetModalNum();
      this.$refs.documentComponent.resetModalDoc();
      this.$swal({
        title: "",
        text: "تمت أضافة العائلة بنجاح",
        icon: "success",
        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getFamilyPhone(value) {
      this.familyFile.family_contact_details = value;

      this.saveNumbers = true;
    },
    getFamilyDocuments(value) {
      this.familyFile.family_documents = value;
      this.saveDocuments = true;
    },

    callFunction() {
    //   var currentDateWithFormat = new Date().toJSON().slice(0, 10);
      var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

      this.familyFile.registration_date = today;
    },
  },
};
</script>

<style scoped>
@import "~flatpickr/dist/flatpickr.css";
span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.step-progress__step-label {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.swal2-content {
  /* font-weight: bold; */
  font-family: "Cairo", sans-serif;
  font-weight: 900;
}
.card .card-title {
  font-weight: 900;
  color: red !important;
  font-size: 50px;
}
.btn-purple {
  color: white;
  width: 200px;
  background-color: #00468e !important;
}
.my-custom-class .card-title {
  color: red;
}
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
ul {
  font-size: 500px;
  color: red !important;
}
.but {
  top: 40px;
}
h6 {
  color: #7e7a9f;
}
.flo {
  margin-left: 1000px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>